import React, { Component } from 'react'
import sa from "../../../utils/sa"

class RequestPin extends Component {
  async postData() {
    try {
      sa({event: 'twitter_page_pin_requested'});
    } catch(err) {
      console.log(err)
    }
  }

  render() {
    this.postData()
    return ('')
  }
}

export default RequestPin