import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import sa from "../../utils/sa";

const isCode = (code) =>
    /^\d+$/i.test(code);
 
export default function App() {
  const [values, setValues] = useState({ code: "" });
  const [errors, setErrors] = useState({});
  const [clicked, setClicked] = useState(false);
 
  const validateCodeAndSubmitForm = (e) => {
    
    e.preventDefault();
 
    const errors = {};
 
    if (!isCode(values.code)) {
      errors.code = "Please add your code";
    }
 
    setErrors(errors);
 
    if (!Object.keys(errors).length) {
        console.log(values.code)
      // window.location.href = `https://reg3.bluemailapp.com/v3/twitter/login?email=${values.email}&brand=BL&redirect_uri=https://bluemail.me/twitter`
      
      function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
      }
      const tk = getParameterByName('tk');
      const tkLink = typeof window !== 'undefined' ? window.location.href.substring(window.location.href.lastIndexOf('=') + 1) : '';

      var postData = {
        pin_code: values.code,
        tk: tkLink
      };
      
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
      };
      
      axios.post('https://reg3.bluemailapp.com/v3/twitter/verification/verify', postData, axiosConfig)
      .then((res) => {
        if (res.status == 200) {
            window.location = `/twitter__?form&success=true&email=true&tk=${tkLink}&successLogIn` 
            //analytics user entered correct pin
            try {
              sa({event: 'twitter_page_pin_success'});
            } catch(err) {
              console.log(err)
            }
        }  
      })
      .catch((err) => {
        console.log(err);
        //analytics user entered incorrect pin
        try {
          sa({event: 'twitter_page_pin_failed'});
        } catch(err) {
          console.log(err)
        }
      })    
    }
  };
 
  const setCode = (e) => {
    setValues((values) => ({ ...values, code: e.target.value }));
  };  

  const sendAnalyticsCode = () => {
    try {
      sa({event: 'twitter_page_pin_entered'});
    } catch(err) {
      console.log(err)
    }
  };
  const handleKeyUp = (event) => {
    if (!clicked) {
      setClicked(true);
      sendAnalyticsCode(event);
    }
  };
 
  return (
    <div>
      <form onSubmit={validateCodeAndSubmitForm}>        
        <input
          type="number"
          id="userCode"
          value={values.code}
          onChange={setCode}
          placeholder={'Enter code'}
          onKeyUp={handleKeyUp}
        />
        <input id="codeSubmit" type="submit" />
        <br />
        {Object.entries(errors).map(([key, error]) => (
          <span
            key={`${key}: ${error}`}
            style={{
              color: "red",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "45px",
            }}
          >
            &nbsp; <br /> {error}!        
          </span>
        ))}
      </form>
    </div>
  );
}