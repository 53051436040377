import React, { Component } from 'react'
import signIn from '../../../assets/images/twitter/sign_in_twitter.svg'
import sa from "../../../utils/sa"

class ClickTwitter extends Component {
  async postData() {
    try {
      sa({event: 'twitter_page_login_clicked'});
    } catch(err) {
      console.log(err)
    }
  }

  render() {
    return (
        <button onClick={ () => this.postData()} style={{border: '0', padding: '0', margin: '0', background: 'none'}}>
            <img src={signIn} alt='sign in twitter' />
        </button>
    )
  }
}

export default ClickTwitter