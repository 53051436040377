import React, {useState, useEffect} from 'react';
import Layout from '../components/App/Layout';
import SEO from '../components/App/SEO';
import { graphql, Link } from 'gatsby';
import EmailValidation from '../components/Twitter/EmailValidation';
import CodeValidation from '../components/Twitter/CodeValidation';
import logo from '../assets/images/logo-bluemail.svg'
import hr from '../assets/images/twitter/hr.svg'
import successLogIn from '../assets/images/twitter/successLogIn.svg'
import connectEmail from '../assets/images/twitter/connect_email.svg'
import signIn from '../assets/images/twitter/sign_in_twitter.svg'
import Cookie from "js-cookie";
import {ajax} from 'rxjs/ajax';
import buildUrl from 'build-url';
import axios from 'axios';

//analytics
import PageOpen from '../components/Twitter/Analytics/PageOpen';
import ClickTwitter from '../components/Twitter/Analytics/ClickTwitter';
import TwitterSuccessLogIn from '../components/Twitter/Analytics/TwitterSuccessLogIn';
import TwitterFailedLogIn from '../components/Twitter/Analytics/TwitterFailedLogIn';
import RequestPin from '../components/Twitter/Analytics/RequestPin';

const ajaxWithXSRF = (method) => (url, body, queryParams) => ajax({
    url: buildUrl(url, {queryParams: method === 'GET' ? body : queryParams}),
    method,
    crossDomain: true,
    headers: {
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN'),
    },
    body: method === 'GET' ? undefined : body,
});

function getParameterByName(name, url = typeof window !== 'undefined' ? window.location.href : '') {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function Twitter() {

    // const [buttonPopup, setButtonPopup] = useState(false);    needed in case of pop up
    const [notSigned, setNotSigned] = React.useState(false)
    const [userVerification, setUserVerification] = useState({
        verifiedTwitter: undefined,
        verifiedEmail: undefined,
        emailToken: getParameterByName("tk"),
    });
    // const [isTwitterVerified, setIsTwitterVerified] = React.useState(false)
    // const [isEmailVerified, setIsEmailVerified] = React.useState(false)

    const connectError = () => setNotSigned(false)

    const Results = () => (
        <div style={{color: "#de0a26", fontWeight: "bold", fontSize: "13px"}}>
          Sign in with Twitter first <br />
        </div>
    )

    useEffect(async () => {
        // TODO: request for token, selfportal 
        //ajaxWithXSRF("GET")("/api/twitterData").subscribe();
        try {
            const twStatRes = await axios.get("/api/twitter-account/connected", { withCredentials: true })
            const twStat = twStatRes.data && twStatRes.data.twData;
            if (twStat) {
                setUserVerification({verifiedTwitter: twStat.twitterUserAt, verifiedEmail: twStat.email})
            }
        } catch(e) {
            console.error(e);
        }

        if (emailToken) {
            userVerification.emailToken = emailToken
            setUserVerification(userVerification)
        }
    }, []);
 
    const { verifiedTwitter, verifiedEmail, emailToken } = userVerification;
    const isTwitterVerified = !!verifiedTwitter;
    const isEmailVerified = !!verifiedEmail;

    const form = typeof window !== 'undefined' ? window.location.href.includes('form') : '';
    // const isTwitterVerified = true;//typeof window !== 'undefined' ? window.location.href.includes('success=true') : '';
    const redirect_uiFalse = typeof window !== 'undefined' ? window.location.href.includes('success=false') : '';
    const emailStep = isEmailVerified;//typeof window !== 'undefined' ? window.location.search.includes('email') : '';
    const success = isEmailVerified;//typeof window !== 'undefined' ? window.location.href.includes('successLogIn') : '';
    // const redhost = window.location.host;
    const redorigin = typeof window !== 'undefined' ? window.location.origin + `/api/twitter/login/callback?redirect_uri=${window.location.origin}/twitter__?form` : '';
    const tkLink = typeof window !== 'undefined' ? window.location.href.includes('&tk=') : '';

    return (
        <Layout>
        <SEO 
            postTitle='Twitter LogIn | BlueMail App' 
            postDescription='Send Tweeter threads to your email account'
            postImage=''
            postURL='twitter'
            postSEO
        />
            
            {/* Analytics */}
            {isTwitterVerified && <TwitterSuccessLogIn />}
            {redirect_uiFalse && <TwitterFailedLogIn />}
            {!emailStep && <RequestPin />}
    
        <div className="container mt-5 twitter">
            <div style={{background: "#FFFFFF", padding: "0 15px"}}>
                <div className='row'>
                    <div className='col-xl-4 col-md-6 col-xs-9 m-auto' style={{borderRadius: "10px", padding: "15px", boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.75)"}}>
                        <div className='row'>
                            <div className='col d-block m-auto text-center'>
                                <img style={{ paddingTop: '20px'}} src={logo} alt='logo' />
                            </div>
                        </div>
                        <div style={{ paddingTop: '40px', marginBottom: '20px'}}>
                            <div className='grid-wrapper' style={{textAlign: 'center'}}>                    
                                {!form && !tkLink && <div className='row'>
                                    <PageOpen />
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-2 mb-3'>
                                                <p style={{fontSize: "16px", lineHeight: "21px"}}>Send tweets in a readable form to 
                                                your email so you can choose when to read them and never miss an important thread again.</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-3 mb-3'>
                                                <Link to='/twitter__?form'>
                                                    <button className="default-btn" style={{padding: "8px 100px"}}>
                                                        Continue
                                                    </button>
                                                </Link>
                                            </div>                                
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-2 mb-2'>
                                                <img src={hr} alt='hr line'/>
                                            </div>                                
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-2 mb-1'>
                                                <p style={{fontSize: "16px", lineHeight: "21px", fontWeight: "bold"}}>Already linked your Twitter to BlueMail?</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-3 mb-3'>
                                                <Link to='/twitter/commands/'>
                                                    <button className="default-btn-white" style={{padding: "8px 30px"}}>
                                                        Twitter Assist
                                                    </button>
                                                </Link>
                                            </div>                                
                                        </div>
                                    </div>
                                </div>
                                }
                                {form && !success && !tkLink && <div className='row'>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-2 mb-3'>
                                                <p style={{fontSize: "20px", lineHeight: "24px"}}>Step 1 - Twitter Verification</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-2 mb-1'>
                                                { emailToken && <a href={`https://reg3.bluemailapp.com/v3/twitter/login?tk=${emailToken}&redirect_uri=${redorigin}`}>
                                                    <ClickTwitter />
                                                </a> } 
                                                { emailToken == null && <a href={`https://reg3.bluemailapp.com/v3/twitter/login?redirect_uri=${redorigin}`}>
                                                    <ClickTwitter />
                                                </a> } 
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-1 mb-3'>
                                                <p style={{fontSize: "16px", lineHeight: "21px"}}><span style={{fontWeight: "bold"}}>Status: </span> 
                                                    {isTwitterVerified && <span>Verified</span> ? <span>Verified &nbsp;<span className='greenDot'></span></span> : <span>Not verified yet &nbsp;<span className='redDot'></span></span>} 
                                                    {redirect_uiFalse && <span style={{color: "#de0a26"}}><br />Not successful log in. <br /> Please try again</span> ? <span style={{color: "#de0a26"}}><br />Not successful log in. <br /> Please try again</span> : ''} 
                                                </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-2 mb-2'>
                                                <img src={hr} alt='hr line'/>
                                            </div>                                
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-2 mb-3'>
                                                <p style={{fontSize: "20px", lineHeight: "24px"}}>Step 2 - Verify your email</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                {isTwitterVerified ? <div className='row'>
                                                    <div className='col'>
                                                        {!emailStep && <div className='row'>
                                                            <div className='col ml-auto mr-auto mt-2 mb-3'>
                                                            {/* <button onClick={() => setButtonPopup(true) } style={{border: "none", padding: '0'}}><img src={connectEmail} alt="twitter image" /></button> */}
                                                                {/* <Popup trigger={buttonPopup} setTrigger={setButtonPopup}> */}
                                                                    <EmailValidation />
                                                                {/* </Popup> */}
                                                            </div>
                                                        </div>}
                                                        {emailStep && <div className='row'>
                                                            <div className='col ml-auto mr-auto mt-2 mb-3'>
                                                                <CodeValidation />
                                                            </div>
                                                        </div>}
                                                    </div>
                                                    
                                                </div> : <div className='row'>
                                                    <div className='col'>
                                                        <div className='row'>
                                                            <div className='col ml-auto mr-auto mt-2 mb-1'>
                                                                <button onClick={() => connectError() } style={{border: "none", padding: '0'}}>
                                                                    <img style={{mixBlendMode: "soft-light"}} src={connectEmail} alt="twitter image" />   
                                                                </button>                                                
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col ml-auto mr-auto mt-2 mb-1'>
                                                                { notSigned ? <Results /> : null }                                          
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-2 mb-3'>
                                                <p style={{fontSize: "16px", lineHeight: "21px"}}><span style={{fontWeight: "bold"}}>Status</span>: <span>Not verified yet &nbsp;<span className='redDot'></span></span></p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-5 mb-3'>
                                                <p style={{fontSize: "16px", lineHeight: "21px"}}>Please complete the two steps above to 
                                                link your twitter account to your email address.</p>
                                            </div>
                                        </div>
                                    </div>                            
                                </div>  
                                }  
                                {success && <div className='row'>
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-2 mb-3'>
                                                <p style={{fontSize: "20px", lineHeight: "26px"}}>Success!</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-2 mb-3'>
                                                <img src={successLogIn} alt='success log in twitter' />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-3 mb-3'>
                                            <p style={{fontSize: "20px", lineHeight: "26px"}}>{`Your Twitter account ${verifiedTwitter}`}<br/> {`is now connected to your email address`} <br /> {`${verifiedEmail}`}</p>
                                            </div>                                
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-3 mb-3'>
                                                <Link to='/twitter/commands/'>
                                                    <button className="default-btn command" style={{padding: "8px 30px"}}>
                                                        Twitter Assist
                                                    </button>
                                                </Link>
                                            </div>                                
                                        </div>
                                    </div>
                                </div>
                                }     
                                {tkLink && <div className='row'>
                                    <div className='col'>                                        
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-2 mb-1'>
                                                { emailToken && <a href={`https://reg3.bluemailapp.com/v3/twitter/login?tk=${emailToken}&redirect_uri=${redorigin}`}>
                                                    <ClickTwitter />
                                                </a> } 
                                                { emailToken == null && <a href={`https://reg3.bluemailapp.com/v3/twitter/login?redirect_uri=${redorigin}`}>
                                                    <ClickTwitter />
                                                </a> } 
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-1 mb-3'>
                                                <p style={{fontSize: "16px", lineHeight: "21px"}}><span style={{fontWeight: "bold"}}>Status: </span> 
                                                    {isTwitterVerified && <span>Verified</span> ? <span>Verified &nbsp;<span className='greenDot'></span></span> : <span>Not verified yet &nbsp;<span className='redDot'></span></span>} 
                                                    {redirect_uiFalse && <span style={{color: "#de0a26"}}><br />Not successful log in. <br /> Please try again</span> ? <span style={{color: "#de0a26"}}><br />Not successful log in. <br /> Please try again</span> : ''} 
                                                </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-2 mb-2'>
                                                <img src={hr} alt='hr line'/>
                                            </div>                                
                                        </div>
                                        <div className='row'>
                                            <div className='col ml-auto mr-auto mt-3 mb-3'>
                                            <p style={{fontSize: "16px", lineHeight: "21px"}}>Please link your twitter account to BlueMail.</p>
                                            </div>                                
                                        </div>
                                    </div>
                                </div>
                                }           
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
        </Layout>
    )
}

export default Twitter;

export const query = graphql`
query TwitterPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
