import React, { useState } from "react";
import axios from "axios";
 
const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
 
export default function App() {
  const [values, setValues] = useState({ email: "" });
  const [errors, setErrors] = useState({});
 
  const validateAndSubmitForm = (e) => {
    e.preventDefault();
 
    // const errors = {};
 
    // if (!isEmail(values.email)) {
    //   errors.email = "Wrong email";
    // }
 
    // setErrors(errors);
 
    function getParameterByName(name, url = window.location.href) {
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    const tk = getParameterByName('tk');
    const tkLink = typeof window !== 'undefined' ? window.location.href.substring(window.location.href.lastIndexOf('=') + 1) : '';
    
    if (!Object.keys(errors).length) {
      // window.location.href = `https://reg3.bluemailapp.com/v3/twitter/login?email=${values.email}&brand=BL&redirect_uri=https://bluemail.me/twitter`
      var postData = {
        email: values.email,
        brand: "BL",
        tk
      };
      
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
      };
      
      // const width = 600, height = 600;
      // const left = (window.innerWidth / 2) - (width / 2);
      // const top = (window.innerHeight / 2) - (height / 2);

      let paneName;
      if (window.location && window.location.pathname && window.location.pathname !== "/") {
        paneName = window.location.pathname.replace("/", "");
        paneName = paneName.charAt(0).toUpperCase() + paneName.slice(1);
      }
   
      const returnTo = window.location.pathname;

      const redorigin = typeof window !== 'undefined' ? `${window.location.origin}/twitter__/?form` : "";

      const params =  (window.location.search ? window.location.search + `&returnTo=${redorigin}` + returnTo : `?returnTo=${redorigin}` + returnTo);

      console.error(`http://localhost:8005/auth/login/${params}`);
      window.open(`/auth/login/${params}`, '_blank');
//       `toolbar=no, location=no, directories=no, status=no, menubar=no, 
// scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
// height=${height}, top=${top}, left=${left}`);   
    }
  };
 
  const setEmail = (e) => {
    setValues((values) => ({ ...values, email: e.target.value }));
  };
 
  return (
    <div>
      {/* <button></button> */}
      <form onSubmit={validateAndSubmitForm}>
        
        {/* <input
          type="text"
          id="userEmail"
          value={values.email}
          onChange={setEmail}
          placeholder={'Enter your email'}
        />{" "} */}
        <input id="emailSubmit" type="submit" value="Verify Email"/>
        <br />
        {Object.entries(errors).map(([key, error]) => (
          <span
            key={`${key}: ${error}`}
            style={{
              color: "red",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "45px",
            }}
          >
            &nbsp; <br /> {error}!        
          </span>
        ))}
      </form>
    </div>
  );
}